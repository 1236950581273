#fixed-bottom-area {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1011;
}

#catalogue-ipaper-root ~ #fixed-bottom-area {
    z-index: 1550;
}

.banners {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.icons {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 100%;
    left: auto;
    right: 0;
}

#fixed-bottom-area .ui-cookie-notification.shown {
    position: static;
}

#fixed-bottom-area .bottom-icon {
    margin-bottom: 10px;
}

#fixed-bottom-area .n-header-sponsor {
    width: 45px;
    height: 45px;
    margin: 0 45px 15px 0;
    padding-right: 0;
    order: 0;
}

#fixed-bottom-area .n-header-item-link {
    width: 45px;
    height: 45px;
}

#fixed-bottom-area .n-header-item-icon {
    border-radius: 50%;
    background: var(--secondary-main);
    width: 45px;
    height: 45px;
    align-items: center;
    justify-content: center;
}

#fixed-bottom-area .n-header-item-image-icon {
    width: 45px;
    height: 45px;
    box-sizing: border-box;
}

#fixed-bottom-area .ui-chat-btn,
#fixed-bottom-area #back-to-top-button {
    bottom: 0;
}

#fixed-bottom-area .n-header-item-link,
#fixed-bottom-area .n-header-item-link:hover {
    color: var(--common-white);
}

@media (max-width: 1024px) {
    #fixed-bottom-area .n-header-sponsor,
    #fixed-bottom-area .bottom-icon {
        margin-right: 10px;
        right: 0;
    }
}
